<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Genel') }}</span>
      </template>

      <profile-setting-general
      v-if="loaded"
      :profileData="options.data"
      />
    </b-tab>
    <!--/ general tab -->
    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Parola') }}</span>
      </template>

      <profile-setting-password />
    </b-tab>
    <!--/ change password tab -->
  </b-tabs>
</template>

<script>
import ProfileSettingGeneral from './ProfileSettingGeneral.vue'
import ProfileSettingPassword from './ProfileSettingPassword.vue'

export default {
  components: {
    ProfileSettingGeneral,
    ProfileSettingPassword,
  },
  data() {
    return {
      loaded: false,
      options: { data: {} },
    }
  },
  beforeCreate() {
    this.$store.dispatch('profileSettings/fetchProfile')
      .then(response => {
        this.options.data = response.result;
        this.loaded = true;
      })
  },
}
</script>
